<template>
  <div class="download-page">
    <div class="wx-tips" v-if="isWeiXin()">
      当前是微信环境，请复制本页面地址到外部浏览器打开
    </div>
    <div class="downloading" v-else-if="canDownload">
      <van-loading size="30px" text-size="20px" vertical
        >下载即将开始，如果长时间下载未开始，请再次复制链接打开页面重试，并允许当前页面弹出窗口</van-loading
      >
      <span class="text"> </span>
    </div>
  </div>
</template>

<script>
  import { isWeiXin } from '@/utils/ua'
  import { downloadFile, saveFile } from '@/utils/downloadFile'

  export default {
    name: 'download',
    data() {
      return {
        downloadUrl: undefined,
        fileName: undefined,
      }
    },
    computed: {
      // 正常的下载页面
      canDownload() {
        // 不是微信页面，且有下载地址
        return !this.isWeiXin() && this.downloadUrl
      },
    },
    created() {
      this.getQuery()
      this.downloadFile(this.downloadUrl, this.fileName)
    },
    methods: {
      isWeiXin,
      getQuery() {
        const { downloadUrl, fileName } = this.$route.query
        this.downloadUrl = downloadUrl || undefined
        this.fileName = fileName || undefined
      },
      downloadFile(url, fileName) {
        // 非微信环境
        // 有downloadUrl
        if (!this.isWeiXin() && url) {
          window.open(url)
          // downloadFile(url, fileName);
        }
      },
    },
  }
</script>

<style scoped lang="less">
  .download-page {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    font-size: 24px;
    line-height: 1.5;
    padding: 128px 24px 48px;
    display: flex;
    .wx-tips {
    }
    .downloading {
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon {
        font-size: 48px;
      }
      .text {
        margin-top: 24px;
      }
    }
  }
</style>
